'use client';
import Link from 'next/link';
import OfferPayout from '../OfferPayout';
import {
  DAILY_CHECK_IN_PAGE,
  PROFILE_PAGE,
  WALLET_PAGE,
} from '@/constants/routes.const';
import useService from '@/hooks/useService';
import { useEffect, useState } from 'react';
import Avatar from '../Avatar';
import { DailyCheckinService } from '@/service/DailyCheckinService';
import DailyRewardCheckIn from '/public/assets/icons/rewards.svg';
import { DailyCheckin } from '@/model/DailyCheckin';
import Skeleton from 'react-loading-skeleton';
import { cn } from '@/utils';
import useAuthContext from '@/context/authContext';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

export default function AppBar() {
  const {
    authUser: userDetails,
    walletBalance,
    refreshWallet,
    isBalanceLoaded,
    isLoaded: isProfileLoaded,
  } = useAuthContext();

  /**
   * @type {{data: DailyCheckin}}
   */
  const { call: getDailyCheckinStatus, data: dailyCheckinStatus } = useService(
    DailyCheckinService.getDailyCheckinStatus
  );

  useEffect(() => {
    getDailyCheckinStatus();
    refreshWallet();
  }, []);
  const isClaimed = !dailyCheckinStatus?.canClaimToday();

  const [prevScrollPos, setPrevScrollPos] = useState(100);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos < 100) {
      setVisible(true);
    } else if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div
      className={cn(
        'fixed inset-x-0 z-[100] top-0  max-h-[80px]',
        visible ? 'block' : 'hidden'
      )}
    >
      <div className="mx-auto flex max-w-[var(--max-width)] bg-gradient-to-r from-[var(--blue-7)] to-[var(--blue-4)] justify-between gap-4 px-2  py-2 md:px-8 lg:px-10">
        <Profile userDetails={userDetails} isLoaded={isProfileLoaded} />
        <div className="flex items-center gap-2 ml-auto">
          {!isClaimed && (
            <Link
              href={DAILY_CHECK_IN_PAGE}
              className="p-2 rounded-md bg-accent-color/10 animate-pulse"
            >
              <DailyRewardCheckIn className="w-[20px] h-[20px]" />
            </Link>
          )}
          {isBalanceLoaded && (
            <Link href={WALLET_PAGE} className="grid rounded">
              <OfferPayout
                amount={walletBalance || 0}
                size="medium"
                classes={{
                  text: 'font-bold text-white text-xl',
                  root: 'justify-end',
                }}
              />
              <div className="text-xs text-gray justify-self-end">
                {LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'].wallet}
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

const Profile = ({ userDetails, isLoaded }) => {
  if (!isLoaded) {
    return (
      <div className="flex items-center gap-1">
        <Skeleton borderRadius={100} width={40} height={40} />
        <Skeleton height={25} width={80} />
      </div>
    );
  }
  return (
    <div className="flex items-center justify-start">
      <Link
        title="Sikka"
        href={PROFILE_PAGE}
        className="flex items-center gap-1"
      >
        <Avatar user={userDetails || {}} />
        <p className="text-lg ms-1 font-semibold text-white line-clamp-1 text-ellipsis  max-w-[150px]">
          {userDetails?.fullName}
        </p>
      </Link>
    </div>
  );
};
